<template>
	<div>
		<div v-if="showForm">
			<show-announcement
				:theme="form.theme"
				:content="form.content" />
			<form
				@submit.prevent="announcementCreateSubmit"
				@keydown="clearError($event.target.name);"
				@change="clearError($event.target.name);">
				<!-- <input-text
					id="title"
					:label="translate('title')"
					:setter-value="form.title"
					:errors="errors['title']"
					:required="true"
					type="text"
					@dataChanged="form.title = $event" /> -->

				<div class="form-group">
					<label
						for="content"
						class="label">
						{{ translate('content') }}
						<span class="text-danger">*</span>
					</label>
					<vue-html5-editor
						ref="editor"
						:content="form.content"
						:height="250"
						:auto-height="false"
						:class="hasError('content') ? 'is-invalid' : ''"
						@change="updateData" />
					<template v-if="hasError('content')">
						<span
							v-for="error in errors['content']"
							:key="error"
							class="custom-invalid-feedback animated fadeIn"
							v-text="error" />
					</template>
				</div>

				<!-- <input-text
					id="regards"
					:label="translate('regards')"
					:setter-value="form.regards"
					:errors="errors['regards']"
					:required="false"
					type="text"
					@dataChanged="form.regards = $event" /> -->

				<!-- <input-text
					id="signature"
					:label="translate('signature')"
					:setter-value="form.signature"
					:errors="errors['signature']"
					:required="false"
					type="text"
					@dataChanged="form.signature = $event" /> -->

				<div class="form-group">
					<label
						for="theme"
						class="label">
						{{ translate('theme') }}
						<span class="text-danger">*</span>
					</label>
					<select
						id="theme"
						v-model="form.theme"
						:class="hasError('theme') ? 'is-invalid' : ''"
						:disabled=" (!Object.keys(themes).length > 0) ? true : false"
						type="text"
						name="theme"
						class="form-control">
						<option
							v-for="theme in themes"
							:key="theme"
							:value="theme">
							{{ translate(theme) }}
						</option>
					</select>
					<template v-if="hasError('theme')">
						<span
							v-for="error in errors['theme']"
							:key="error"
							class="invalid-feedback animated fadeIn"
							v-text="error" />
					</template>
				</div>

				<div class="form-group">
					<label
						for="priority"
						class="label">
						{{ translate('priority') }}
						<span class="text-danger">*</span>
					</label>
					<select
						id="priority"
						v-model="form.priority"
						:class="hasError('priority') ? 'is-invalid' : ''"
						:disabled=" (! Object.keys(priorities).length > 0) ? true : false"
						type="text"
						name="priority"
						class="form-control">
						<option
							v-for="priority in priorities"
							:key="priority"
							:value="priority">
							{{ translate(priority) }}
						</option>
					</select>
					<template v-if="hasError('priority')">
						<span
							v-for="error in errors['priority']"
							:key="error"
							class="invalid-feedback animated fadeIn"
							v-text="error" />
					</template>
				</div>

				<div class="form-group">
					<div class="form-check">
						<input
							id="allow_to_hide"
							v-model="form.allow_to_hide"
							name="allow_to_hide"
							class="form-check-input"
							type="checkbox">
						<label
							for="allow_to_hide"
							class="label">
							{{ translate('allow_to_hide') }}
						</label>
					</div>
					<template v-if="hasError('allow_to_hide')">
						<span
							v-for="error in errors['allow_to_hide']"
							:key="error"
							class="invalid-feedback animated fadeIn"
							v-text="error" />
					</template>
				</div>

				<div class="row form-group">
					<div class="col-md-12 p-0">
						<label
							for="start_date"
							class="label d-block px-3">
							{{ translate('start_date') }}
							<span class="text-danger">*</span>
						</label>
						<div
							:class="hasError('start_date') ? 'is-invalid' : ''"
							:style="'height: auto'"
							class="form-control border-0">
							<select
								id="selectedStartYear"
								v-model="selectedStartYear"
								type="text"
								class="form-control col-xl-2 col-sm-4 d-inline"
								name="year"
								@change="updateDays('start')">
								<option
									value=""
									selected
									disabled>
									{{ translate('year') }}
								</option>
								<option
									v-for="year in years"
									:key="year"
									:value="year">
									{{ year }}
								</option>
							</select>
							<select
								id="selectedStartMonth"
								v-model="selectedStartMonth"
								type="text"
								class="form-control col-xl-2 col-sm-4 d-inline"
								name="month"
								@change="updateDays('start')">
								<option
									value=""
									selected
									disabled>
									{{ translate('month') }}
								</option>
								<option
									v-for="month in months"
									:key="month"
									:value="month">
									{{ month }}
								</option>
							</select>
							<select
								id="selectedStartDay"
								v-model="selectedStartDay"
								type="text"
								class="form-control col-xl-2 col-sm-4 d-inline"
								name="day">
								<option
									value=""
									selected
									disabled>
									{{ translate('day') }}
								</option>
								<option
									v-for="day in startDays"
									:key="day"
									:value="day">
									{{ day }}
								</option>
							</select>
							<select
								id="selectedStartHour"
								v-model="selectedStartHour"
								type="text"
								class="form-control col-xl-1 col-sm-4 d-inline"
								name="hour">
								<option
									value=""
									selected
									disabled>
									{{ translate('hour') }}
								</option>
								<option
									v-for="hour in hours"
									:key="hour"
									:value="hour">
									{{ hour }}
								</option>
							</select>
							<select
								id="selectedStartMinute"
								v-model="selectedStartMinute"
								type="text"
								class="form-control col-xl-1 col-sm-4 d-inline"
								name="minute">
								<option
									value=""
									selected
									disabled>
									{{ translate('minute') }}
								</option>
								<option
									v-for="minute in minutes"
									:key="minute"
									:value="minute">
									{{ minute }}
								</option>
							</select>
							<div
								class="alert alert-light col-4 d-inline py-0 px-2 font-italic"
								role="alert">
								{{ form.start_date }}
							</div>
						</div>
						<template v-if="hasError('start_date')">
							<span
								v-for="error in errors['start_date']"
								:key="error"
								class="invalid-feedback animated fadeIn px-3"
								v-text="error" />
						</template>
					</div>
				</div>
				<div class="row form-group">
					<div class="col-md-12 p-0">
						<label
							for="end_date"
							class="label d-block px-3">
							{{ translate('end_date') }}
						</label>
						<div
							:class="hasError('end_date') ? 'is-invalid' : ''"
							:style="'height: auto'"
							class="form-control border-0">
							<select
								id="selectedEndYear"
								v-model="selectedEndYear"
								type="text"
								class="form-control col-xl-2 col-sm-4 d-inline"
								name="year"
								@change="updateDays('end')">
								<option
									value=""
									selected>
									{{ translate('year') }}
								</option>
								<option
									v-for="year in years"
									:key="year"
									:value="year">
									{{ year }}
								</option>
							</select>
							<select
								id="selectedEndMonth"
								v-model="selectedEndMonth"
								type="text"
								class="form-control col-xl-2 col-sm-4 d-inline"
								name="month"
								@change="updateDays('end')">
								<option
									value=""
									selected>
									{{ translate('month') }}
								</option>
								<option
									v-for="month in months"
									:key="month"
									:value="month">
									{{ month }}
								</option>
							</select>
							<select
								id="selectedEndDay"
								v-model="selectedEndDay"
								type="text"
								class="form-control col-xl-2 col-sm-4 d-inline"
								name="day">
								<option
									value=""
									selected>
									{{ translate('day') }}
								</option>
								<option
									v-for="day in endDays"
									:key="day"
									:value="day">
									{{ day }}
								</option>
							</select>
							<select
								id="selectedEndHour"
								v-model="selectedEndHour"
								type="text"
								class="form-control col-xl-1 col-sm-4 d-inline"
								name="hour">
								<option
									value=""
									selected>
									{{ translate('hour') }}
								</option>
								<option
									v-for="hour in hours"
									:key="hour"
									:value="hour">
									{{ hour }}
								</option>
							</select>
							<select
								id="selectedEndMinute"
								v-model="selectedEndMinute"
								type="text"
								class="form-control col-xl-1 col-sm-4 d-inline"
								name="minute">
								<option
									value=""
									selected>
									{{ translate('minute') }}
								</option>
								<option
									v-for="minute in minutes"
									:key="minute"
									:value="minute">
									{{ minute }}
								</option>
							</select>
							<div
								class="alert alert-light col-4 d-inline py-0 px-2 font-italic"
								role="alert">
								{{ form.end_date }}
							</div>
						</div>
						<template v-if="hasError('end_date')">
							<span
								v-for="error in errors['end_date']"
								:key="error"
								class="invalid-feedback animated fadeIn px-3"
								v-text="error" />
						</template>
					</div>
				</div>
				<input-text
					id="password"
					:label="translate('password')"
					:setter-value="form.password"
					:errors="errors['password']"
					:required="true"
					type="password"
					@dataChanged="form.password = $event" />

				<button
					:disabled="Object.keys(errors).length > 0"
					class="btn btn-primary float-right">
					{{ translate('submit') }}
				</button>
			</form>
		</div>
		<is-loading
			v-if="!createNewAnnouncement"
			:loading="loading"
			:has-data="hasData"
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')" />
	</div>
</template>

<script>
import Vue from 'vue';
import VueHtml5Editor from 'vue-html5-editor';
import InputText from '@/components/InputText';
import ShowAnnouncement from '@/components/Announcement';
import { Announcements as announcementsTranslations, Time } from '@/translations';
import { YMDHMS_FORMAT, YM_FORMAT } from '@/settings/Dates';

Vue.use(VueHtml5Editor, {
	showModuleName: false,
	hiddenModules: [
		'font',
		'tabulation',
		'image',
		'info',
		'full-screen',
		'color',
		// 'text',
		// 'align',
		// 'list',
		// 'link',
		// 'unlink',
		// 'hr',
		// 'eraser',
		// 'undo',
	],
	icons: {
		text: 'fa fa-pen',
		link: 'fa fa-link',
		unlink: 'fa fa-unlink',
	},
});

export default {
	name: 'AnnouncementData',
	messages: [announcementsTranslations, Time],
	components: {
		InputText,
		ShowAnnouncement,
	},
	props: {
		formImport: {
			type: Object,
			default() {
				return {};
			},
		},
		errorsImport: {
			type: Object,
			default() {
				return {};
			},
		},
		priorities: {
			type: Object,
			default() {
				return {};
			},
		},
		themes: {
			type: Object,
			default() {
				return {};
			},
		},
		createNewAnnouncement: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		announcementImport: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			form: this.formImport,
			errors: this.errorsImport,
			years: [],
			months: [],
			startDays: [],
			endDays: [],
			hours: [],
			minutes: [],
			selectedStartYear: '',
			selectedStartMonth: '',
			selectedStartDay: '',
			selectedStartHour: '',
			selectedStartMinute: '',
			selectedEndYear: '',
			selectedEndMonth: '',
			selectedEndDay: '',
			selectedEndHour: '',
			selectedEndMinute: '',
			YMDHMS_FORMAT,
			YM_FORMAT,
		};
	},
	computed: {
		start_date() {
			if (this.selectedStartYear !== '' && this.selectedStartMonth !== '' && this.selectedStartDay !== '' && this.selectedStartHour !== '' && this.selectedStartMinute !== '') {
				return this.createDate(this.selectedStartYear, this.selectedStartMonth, this.selectedStartDay, this.selectedStartHour, this.selectedStartMinute);
			}
			return '';
		},
		end_date() {
			if (this.selectedEndYear !== '' && this.selectedEndMonth !== '' && this.selectedEndDay !== '' && this.selectedEndHour !== '' && this.selectedEndMinute !== '') {
				return this.createDate(this.selectedEndYear, this.selectedEndMonth, this.selectedEndDay, this.selectedEndHour, this.selectedEndMinute);
			}
			return '';
		},
		content() {
			return this.form.content;
		},
		showForm() {
			return this.createNewAnnouncement || (!this.createNewAnnouncement && !this.loading && this.hasData);
		},
		hasData() {
			return Object.values(this.announcementImport).length > 0;
		},
	},
	watch: {
		errorsImport(value) {
			this.errors = value;
		},
		announcementImport() {
			this.fetchFormData();
		},
		start_date(newVal) {
			this.form.start_date = newVal;
			this.clearError('start_date');
		},
		end_date(newVal) {
			this.form.end_date = newVal;
			if (typeof newVal === 'undefined') {
				this.form.end_date = '';
			}
			this.clearError('end_date');
		},
		content() {
			this.clearError('content');
		},
	},
	mounted() {
		this.populateTime();
	},
	methods: {
		announcementCreateSubmit() {
			this.$emit('requestData', this.form);
		},
		populateTime() {
			const currentYear = this.$moment().year();
			for (let i = 0; i <= 59; i += 1) {
				if (i <= 20) this.years.push(currentYear + i);
				if (i < 10) {
					this.hours.push(`0${i}`);
					this.minutes.push(`0${i}`);
					if (i > 0) this.months.push(`0${i}`);
				} else {
					if (i <= 23) this.hours.push(`${i}`);
					if (i <= 12) this.months.push(`${i}`);
					this.minutes.push(`${i}`);
				}
			}
		},
		updateDays(id) {
			let year = '';
			let month = '';

			const days = [];
			if (id === 'start') {
				this.startDays = [];
				year = this.selectedStartYear;
				month = this.selectedStartMonth;
			} else if (id === 'end') {
				this.endDays = [];
				year = this.selectedEndYear;
				month = this.selectedEndMonth;
			}
			const maxDaysInMonth = this.$moment(`${year}-${month}`, this.YM_FORMAT).daysInMonth();
			for (let i = 1; i <= maxDaysInMonth; i += 1) {
				if (i < 10) {
					days.push(`0${i}`);
				} else {
					days.push(`${i}`);
				}
			}
			if (id === 'start') {
				this.startDays = days;
				this.selectedStartDay = '01';
			} else if (id === 'end') {
				this.endDays = days;
				this.selectedEndDay = '01';
			}
		},
		createDate(year, month, day, hour, minute) {
			if (year !== +'' && month !== +'' && day !== +'' && hour !== +'' && minute !== +'') {
				return this.$moment(`${year}-${month}-${day} ${hour}:${minute}:00.000000`).format(this.YMDHMS_FORMAT);
			}

			return '';
		},
		dateFormat(date) {
			const formatDate = {};
			formatDate.year = this.$moment(date).format('YYYY');
			formatDate.month = this.$moment(date).format('MM');
			formatDate.day = this.$moment(date).format('DD');
			formatDate.hour = this.$moment(date).format('HH');
			formatDate.minute = this.$moment(date).format('mm');
			return formatDate;
		},
		updateData(data) {
			this.form.content = data;
		},
		hasError(field) {
			if (typeof this.errors[field] !== 'undefined') { return true; }
			return false;
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors = { ...this.errors };
			}
		},
		fetchFormData() {
			Object.keys(this.announcementImport).forEach((keyValue) => {
				this.form[keyValue] = this.announcementImport[keyValue];
				if (keyValue === 'start_date') {
					let formatDate = this.dateFormat(this.announcementImport[keyValue]);
					if (typeof this.announcementImport[keyValue].date !== 'undefined') {
						formatDate = this.dateFormat(this.announcementImport[keyValue].date);
					}
					this.selectedStartYear = formatDate.year;
					this.selectedStartMonth = formatDate.month;
					this.updateDays('start');
					this.selectedStartDay = formatDate.day;
					this.selectedStartHour = formatDate.hour;
					this.selectedStartMinute = formatDate.minute;
				} else if (keyValue === 'end_date' && this.announcementImport[keyValue]) {
					let formatDate = this.dateFormat(this.announcementImport[keyValue]);
					if (typeof this.announcementImport[keyValue].date !== 'undefined') {
						formatDate = this.dateFormat(this.announcementImport[keyValue].date);
					}
					this.selectedEndYear = formatDate.year;
					this.selectedEndMonth = formatDate.month;
					this.updateDays('end');
					this.selectedEndDay = formatDate.day;
					this.selectedEndHour = formatDate.hour;
					this.selectedEndMinute = formatDate.minute;
				}
			});
		},
	},
};
</script>
